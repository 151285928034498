.react-flow__node-input,
.react-flow__node-default,
.react-flow__node-output,
.react-flow__node-group {
  width: auto;
  padding: 0;
  border: 0;
  background-color: transparent;
}
.react-flow__node-input div:has(+ span),
.react-flow__node-default div:has(+ span),
.react-flow__node-output div:has(+ span),
.react-flow__node-group div:has(+ span) {
  opacity: 0;
}
.react-flow__node-input span + div,
.react-flow__node-default span + div,
.react-flow__node-output span + div,
.react-flow__node-group span + div {
  opacity: 0;
}
.react-flow__node-input span,
.react-flow__node-default span,
.react-flow__node-output span,
.react-flow__node-group span {
  transform-origin: 50% 50%;

  rotate: -60deg;
  background-color: aliceblue;
  position: relative;
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}
.react-flow__pane {
  background: rgb(255 255 255 / 61%);
}
