.track-list {
  list-style: none;
  padding: 0;
  height: 350px;
  overflow-y: auto;
}

.track-item {
  padding: 10px;
  cursor: pointer;
  transition: transform 0.3s ease; /* Add a transition for smooth animation */
}

/* Styles for the active (playing) track */
.track-item.active {
  font-weight: bold;
  /* transform: translateX(20px); Slide the active track to the right */
}

/* Styles for tracks above the active track */
.track-item.above-active {
  /* transform: translateY(15px); Slide up slightly */
}

/* Styles for tracks below the active track */
.track-item.below-active {
  /* transform: translateY(-15px); Slide down slightly */
}

.track-list::after {
  content: "";
  display: block;
  clear: both; /* Clear any floats */
}
