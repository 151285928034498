.ql-editor {
  font-size: 20px; /* Replace with your desired font size */
  width: 100%;
  /* border: "none";
   */
}

.ql-container.ql-snow {
  border: 0px !important;
}
.ql-container.ql-snow > div {
  border: 0px !important;
  padding: 0px !important;
  margin: 0px !important;
}

.ql-size-small {
  font-size: 10px;
}
.ql-size-medium {
  font-size: 14px;
}
.ql-size-large {
  font-size: 18px;
}
.ql-custom-read-word {
  font-size: 40px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 0.35em;
  /* color: #4aa805; */
  color: #010231;
  font-family: Rubik;
}
.ql-size-huge {
  font-size: 32px;
}
.ql-container:focus,
.ql-editor:focus {
  outline: none !important; /* Hide focus outline */
}
.ql-color[value="black"] {
  background-color: black !important;
  padding: 0px !important;
  font-size: 0px !important;
  width: 20px !important;
  height: 20px !important;
  margin-right: 1px;
}
.ql-color[value="black"] svg {
  display: none;
}
.ql-color[value="rgb(230, 0, 0)"] {
  background-color: rgb(230, 0, 0) !important;
  padding: 0px !important;
  font-size: 0px !important;
  width: 20px !important;
  height: 20px !important;
  margin-right: 1px;
}
.ql-color[value="rgb(230, 0, 0)"] svg {
  display: none;
}
.ql-color[value="rgb(255, 153, 0)"] {
  background-color: rgb(255, 153, 0) !important;
  padding: 0px !important;
  font-size: 0px !important;
  width: 20px !important;
  height: 20px !important;
  margin-right: 1px;
}
.ql-color[value="rgb(255, 153, 0)"] svg {
  display: none;
}
.ql-color[value="rgb(0, 138, 0)"] {
  background-color: rgb(0, 138, 0) !important;
  padding: 0px !important;
  font-size: 0px !important;
  width: 20px !important;
  height: 20px !important;
  margin-right: 1px;
}
.ql-color[value="rgb(0, 138, 0)"] svg {
  display: none;
}
.ql-color[value="rgb(0, 102, 204)"] {
  background-color: rgb(0, 102, 204) !important;
  padding: 0px !important;
  font-size: 0px !important;
  width: 20px !important;
  height: 20px !important;
  margin-right: 1px;
}
.ql-color[value="rgb(0, 102, 204)"] svg {
  display: none;
}
.ql-color[value="rgb(153, 51, 255)"] {
  background-color: rgb(153, 51, 255) !important;
  padding: 0px !important;
  font-size: 0px !important;
  width: 20px !important;
  height: 20px !important;
  margin-right: 1px;
}
.ql-color[value="rgb(153, 51, 255)"] svg {
  display: none;
}

.ql-editor {
  font-size: 26px; /* Default font size */
}
.ql-editor .ql-size-small {
  font-size: 12px;
}
.ql-editor .ql-size-medium {
  font-size: 26px;
}
.ql-editor .ql-size-large {
  font-size: 20px;
}
.ql-editor .ql-size-huge {
  font-size: 24px;
}
.ql-editor p {
  margin-bottom: 10px; /* Optional: adds some spacing between paragraphs */
}

.MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition {
  min-height: 188px;
  overflow: hidden;
}

.MuiDateCalendar-root {
  height: auto;
  margin-bottom: 20px;
}
