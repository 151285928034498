/* body {
  background-image: url("/public/bg.jpg");
} */

.rhap_main-controls-button {
  border: none;
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.autoplay-not-visible-audio-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.autoplay-not-visible-audio-player button {
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  border-radius: 0;
  width: 100% !important;
  height: 100%;
}
.autoplay-not-visible-audio-player button svg {
  display: none;
}

.audio-player button {
  cursor: pointer;
}

.small-audio-player button {
  width: 30px;
  height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.small-audio-player button svg {
  font-size: 20px;
}

pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
